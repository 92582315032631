<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div slot="header" class="card-head">
				<div>
					<span class="title">{{ $t('seller.customer.coreDataTitle') }}</span>
					<el-popover
						placement="right"
						trigger="hover"
						:content="$t('seller.customer.coreDataTips')">
						<i slot="reference" class="el-icon-warning-outline"></i>
					</el-popover>
					<span class="tips">{{ $t('seller.article.updateTime') }}：{{ Date.now() - 86400000 * 2 | parseTime('{y}-{m}-{d}') }}</span>
				</div>
				<el-date-picker v-model="datetimes" type="date" size="small" value-format="timestamp"
					:picker-options="pickerOptions" :placeholder="$t('seller.placeholder.selectDate')" @change="handleChangePicker">
				</el-date-picker>
			</div>
			<div class="page-core">
				<div class="core-chart">
					<div class="chart-title"><span class="text">{{ $t('seller.customer.totalConsume') }}</span><span class="count">{{ coreData.total_sales || 0}}</span></div>
					<div class="chart-list">
						<div class="item" v-if="coreData.new_customer" :class="{active:coreActive==='new_customer'}" @click="handleChangeCore('new_customer')">
							<div class="item-name">
								<span>{{ 'new_customer' | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.unit.number') }}</span> 
								<el-popover
									placement="right"
									trigger="hover"
									:content="$t('seller.customer.tips1')">
									<i slot="reference" class="el-icon-warning-outline"></i>
								</el-popover>
							</div>
							<template v-if="coreData.new_customer.total > 0">
								<div class="item-bar bar-new" :style="{width: coreData.new_customer.total*45/maxNumber+'%'}"></div>
								<div class="item-count">{{ coreData.new_customer.total }}</div>
							</template>
							<div v-else class="item-tips">{{ $t('seller.customer.nodata1') }}{{ 'new_customer' | typeFilter($t('seller.filters.customerType'))}}{{$t('seller.customer.nodata2')}}</div>
						</div>
						<div class="item" v-if="coreData.old_customer" :class="{active:coreActive==='old_customer'}" @click="handleChangeCore('old_customer')">
							<div class="item-name">
								<span>{{ 'old_customer' | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.unit.number') }}</span> 
								<el-popover
									placement="right"
									trigger="hover"
									:content="$t('seller.customer.tips2')">
									<i slot="reference" class="el-icon-warning-outline"></i>
								</el-popover>
							</div>
							<template v-if="coreData.old_customer.total > 0">
								<div class="item-bar bar-old" :style="{width: coreData.old_customer.total*45/maxNumber+'%'}"></div>
								<div class="item-count">{{ coreData.old_customer.total }}</div>
							</template>
							<div v-else class="item-tips">{{ $t('seller.customer.nodata1') }}{{ 'old_customer' | typeFilter($t('seller.filters.customerType'))}}{{$t('seller.customer.nodata2')}}</div>
						</div>
						<div class="item" v-if="coreData.fans_num" :class="{active:coreActive==='fans_num'}" @click="handleChangeCore('fans_num')">
							<div class="item-name">
								<span>{{ 'fans_num' | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.unit.number') }}</span> 
								<el-popover
									placement="right"
									trigger="hover"
									:content="$t('seller.customer.tips3')">
									<i slot="reference" class="el-icon-warning-outline"></i>
								</el-popover>
							</div>
							<template v-if="coreData.fans_num.total > 0">
								<div class="item-bar bar-fans" :style="{width: coreData.fans_num.total*45/maxNumber+'%'}"></div>
								<div class="item-count">{{ coreData.fans_num.total }}</div>
							</template>
							<div v-else class="item-tips">{{ $t('seller.customer.nodata1') }}{{ 'fans_num' | typeFilter($t('seller.filters.customerType'))}}{{$t('seller.customer.nodata2')}}</div>
						</div>
					</div>
					<div class="chart-bottom">
						<span class="title">{{ coreActive | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.customer.floatSum') }}</span>
						<span class="desc">
							<span>{{ $t('seller.customer.agoDay7') }}</span>
							<span v-if="coreChart.drift_total !== 0" :class="coreChart.drift_total > 0 ?'red':'green'"><i :class="coreChart.drift_total > 0 ?'el-icon-top':'el-icon-bottom'"></i>{{ coreChart.drift_total }}%</span>
							<span v-else>{{ $t('seller.customer.flat') }}</span>
						</span>
					</div>
				</div>
				<div class="core-data">
					<div class="data-card">
						<div class="card-title">
							<span>{{ $t('seller.customer.yesterdayAdd') }}</span>
							<el-popover
								placement="left"
								trigger="hover"
								:content="$t('seller.customer.yesterdayAddTips')">
								<i slot="reference" class="el-icon-warning-outline"></i>
							</el-popover>
						</div>
						<div class="card-figure">
							<span class="count">-</span>
							<span class="desc">
								<span>{{ $t('seller.customer.ratio') }}</span>
								<span v-if="coreChart.contrast !== 0" :class="coreChart.contrast > 0 ?'red':'green'"><i :class="coreChart.contrast > 0 ?'el-icon-top':'el-icon-bottom'"></i>{{ coreChart.contrast }}%</span>
								<span v-else>-</span>
							</span>
						</div>
						<div class="card-chart">
							<core-chart v-if="chartData" :key="chartKey" id="order-chart" :chart-data="chartData" :y-show="false" chart-name="人数" width="100%" height="100%"></core-chart>
						</div>
						<div class="card-bottom">
							<span class="title">{{ $t('seller.customer.addText1') }}{{ coreActive | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.customer.addText2') }}</span>
							<span class="desc">
								<span>{{ $t('seller.customer.agoDay7') }}</span>
								<span v-if="coreChart.drift_change !== 0" :class="coreChart.drift_change > 0 ?'red':'green'"><i :class="coreChart.drift_change > 0 ?'el-icon-top':'el-icon-bottom'"></i>{{ coreChart.drift_change }}%</span>
								<span v-else>-</span>
							</span>
						</div>
					</div>
					<div class="data-card">
						<div class="card-title">
							<span>{{ $t('seller.customer.touchTitle') }}</span>
							<el-popover
								placement="left"
								trigger="hover"
								:content="$t('seller.customer.touchTips')">
								<i slot="reference" class="el-icon-warning-outline"></i>
							</el-popover>
						</div>
						<div class="card-cone" v-if="coreChart.touch">
							<div class="cone-main">
								<div class="cone-item" v-if="coreChart.touch.store">{{ coreChart.touch.store.rate?coreChart.touch.store.rate+'%' : '-' }}</div>
								<div class="cone-item" v-if="coreChart.touch.click">{{ coreChart.touch.click.rate?coreChart.touch.click.rate+'%' : '-' }}</div>
								<div class="cone-item" v-if="coreChart.touch.deal">{{ coreChart.touch.deal.rate?coreChart.touch.deal.rate+'%' : '-' }}</div>
							</div>
							<ul class="cone-box">
								<li v-if="coreChart.touch.store">
									<p>{{ $t('seller.home.into') }}<span>{{ coreChart.touch.store.num > 0 ? coreChart.touch.store.num + '人' : '-' }}</span></p>
									<div><i class="el-icon-bottom"></i></div>
								</li>
								<li v-if="coreChart.touch.click">
									<p>{{ $t('seller.customer.click') }}<span>{{ coreChart.touch.click.num > 0 ? coreChart.touch.click.num + '人' : '-' }}</span></p>
									<div><i class="el-icon-bottom"></i></div>
								</li>
								<li v-if="coreChart.touch.deal">
									<p>{{ $t('seller.customer.deal') }}<span>{{ coreChart.touch.deal.num > 0 ? coreChart.touch.deal.num + '人' : '-' }}</span></p>
								</li>
							</ul>
						</div>
						<div class="card-bottom">
							<span class="title">{{ coreActive | typeFilter($t('seller.filters.customerType'))}}{{ $t('seller.customer.dealAmount') }}</span>
							<span class="desc">
								<span>{{ $t('seller.customer.agoDay7') }}</span>
								<span v-if="coreChart.drift_money !== 0" :class="coreChart.drift_money > 0 ?'red':'green'"><i :class="coreChart.drift_money > 0 ?'el-icon-top':'el-icon-bottom'"></i>{{ coreChart.drift_money }}%</span>
								<span v-else>-</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</el-card>
		<el-card class="seller-card">
			<div slot="header" class="card-head">
				<div>
					<span class="title">{{ $t('seller.customer.crowdTitle') }}</span>
					<span class="tips">{{ $t('seller.customer.crowdTips') }}</span>
				</div>
			</div>
			<div class="crowd-search">
				<ul>
					<li>{{ $t('seller.customer.crowdText1') }}</li>
					<li>
						<el-input v-model="pageQuery.title" size="medium" :placeholder="$t('seller.placeholder.searchCrowd')" prefix-icon="el-icon-search"></el-input>
					</li>
					<li>
						<el-button type="primary" size="medium" round @click="handleSearch">{{ $t('seller.screen.search') }}</el-button>
					</li>
				</ul>
				<ul>
					<li>{{ $t('seller.customer.crowdText2') }} <span>{{ pageTotal }}</span> {{ $t('seller.customer.crowdText3') }}</li>
					<li>
						<router-link :to="{ name: 'customerCrowdcreate' }">
							<el-button type="primary" size="medium" round>{{ $t('seller.actions.addCrowd') }}</el-button>
						</router-link>
					</li>
				</ul>
			</div>
			<div class="crowd-search">
				<ul>
					<li>{{ $t('seller.screen.typeFilter') }}</li>
					<li>
						<el-radio-group v-model="pageQuery.type" size="medium" @change="handleChangeType">
							<el-radio v-for="item in $t('seller.options.crowdType')" :key="item.value" :label="item.value" border>{{ item.label }}</el-radio>
						</el-radio-group>
					</li>
				</ul>
			</div>
			<el-table v-loading="listLoading" :data="pageList" class="seller-table">
				<el-table-column :label="$t('seller.tableHead.crowdName')" min-width="240px">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" size="mini" effect="dark">{{ scope.row.type | typeFilter($t('seller.filters.crowdType'))}}</el-tag>
							<span style="margin-left: 8px;">{{ scope.row.title }}</span>
						</div>
						<div style="color:#999;">{{ scope.row.des }}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.type')">
					<template slot-scope="scope">
						{{ scope.row.type | typeFilter($t('seller.filters.crowdType'))}}
					</template>
				</el-table-column>
				<el-table-column v-if="pageQuery.type === 1">
					<template slot="header">
						{{ $t('seller.article.updateTime') }}
						<el-popover
							placement="top"
							trigger="hover"
							:content="$t('seller.customer.tips4')">
							<i slot="reference" class="el-icon-warning-outline"></i>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span v-if="scope.row.update_time > 0">{{ scope.row.update_time | parseTime() }}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column>
					<template slot="header">
						{{ $t('seller.tableHead.crowdEstimate') }}
						<el-popover
							placement="top"
							trigger="hover"
							:content="$t('seller.customer.tips5')">
							<i slot="reference" class="el-icon-warning-outline"></i>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<span v-if="scope.row.predicts < 10">不足10</span>
						<span v-else>{{ scope.row.predicts }}</span>
					</template>
				</el-table-column>
				<el-table-column v-if="pageQuery.type === 1" :label="$t('seller.tableHead.handle')">
					<template slot-scope="scope">
						<p v-if="scope.row.type === 1">
							<el-button type="text" icon="el-icon-edit-outline" @click="$router.push({name: 'customerCrowdcreate', params: {id:scope.row.crowd_id}})"></el-button>
							<el-button type="text" icon="el-icon-delete" @click="handleDelete(scope)"></el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize" @pagination="getList"></pagination>
		</el-card>
	</el-scrollbar>
</template>

<script>
import coreChart from '@/components/echart/coreChart'
import Pagination from '@/components/seller/pagination'
import { fetchCrowdData, fetchCrowdList, deleteCrowd  } from "@/api/seller/customer"
export default {
	components: { coreChart, Pagination },
	data() {
		return {
			datetimes: Date.now() - 86400000*2,
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > new Date().getTime() - 86400000*2;
				},
			},
			maxNumber: 0,
			coreData: {},
			coreActive: 'new_customer',
			coreChart: {},
			chartData: null,
			chartKey: Math.random(),
			pageQuery: {
				title: '',
				type: 0
			},
			listLoading: false,
			pageList: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10
		}
	},
	created () {
		this.getData()
		this.getList()
	},
	methods: {
		async getData() {
			const params = {
				day: parseInt(this.datetimes / 1000)
			}
			const { data } = await fetchCrowdData(params)
			this.coreData = data
			this.coreChart = data[this.coreActive]
			var max = 0
			if(data.new_customer && data.new_customer.total > max) {
				max = parseInt(data.new_custome.totalr)
			}
			if(data.old_customer && data.old_customer.total > max) {
				max = parseInt(data.old_customer.total)
			}
			if(data.fans_num && data.fans_num.total > max) {
				max = parseInt(data.fans_num.total)
			}
			this.maxNumber = max
			this.getChart()
		},
		getList() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchCrowdList(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		getChart() {
			this.chartData = null
			var xData = []
			var sData = []
			this.coreChart.list.forEach(item => {
				xData.push(item.day)
				sData.push(item.value)
			})
			this.chartData = {
				xData: xData,
				sData: sData
			}
			this.chartKey = Math.random()
		},
		handleChangePicker(val) {
			if(val) {
				this.getData()
			}
		},
		handleChangeType() {
			this.pageCurr = 1
			this.getList()
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleChangeCore(key) {
			this.coreActive = key
			this.coreChart = this.coreData[key]
			this.getChart()
		},
		handleDelete({ row }) {
            this.$confirm(this.$t('seller.confirm.deleteCrowdText'), this.$t('seller.confirm.deleteTitle'), {
				confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
            }).then(() => {
                deleteCrowd({ crowd_id: row.crowd_id }).then(() => {
                    this.getList()
                })
            }).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.card-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			font-size: 18px;
			margin-right: 6px;
		}
		.tips {
			color: #999999;
			margin-left: 12px;
			font-size: 12px;
		}
	}
	.page-core {
		display: flex;
		.core-chart {
			width: 33.33%;
			padding-right: 18px;
			box-sizing: border-box;
			.chart-title {
				.text {
					font-size: 14px;
				}
				.count {
					color: #333333;
					font-size: 18px;
					margin-left: 16px;
				}
			}
			.chart-list {
				.item {
					padding: 0 20px;
					margin-top: 18px;
					height: 38px;
					cursor: pointer;
					background-color: #f9f9f9;
					display: flex;
					align-items: center;
					position: relative;
					.item-name {
						font-size: 14px;
						color: #a3a3a3;
						width: 20%;
						margin-right: 8%;
						white-space: nowrap;
					}
					.item-tips {
						color: #acacac;
						font-size: 12px;
					}
					.item-bar {
						height: 16px;
						border-radius: 2px;
						max-width: 45%;
						&.bar-new {
							background-color: rgb(49, 204, 152);
						}
						&.bar-old {
							background-color: rgb(180, 231, 214);
						}
						&.bar-fans {
							background-color: rgb(171, 105, 255);
						}
					}
					.item-count {
						width: 20%;
						font-size: 16px;
						margin-left: auto;
					}
					&.active {
						background-color: #e6eeff;
						.item-name {
							color: $--seller-primary;
						}
						.item-count {
							color: $--seller-primary;
						}
						&:before {
							content: "";
							width: 0;
							height: 0;
							position: absolute;
							border-color: transparent transparent transparent #e6eeff;
							border-style: solid;
							border-width: 19px 18px;
							right: -36px;
							top: 0;
							z-index: -10;
						}
					}
				}
			}
			.chart-bottom {
				margin-top: 12px;
				padding: 12px;
				height: 52px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background-color: #f7faff;
				.title {
					font-size: 14px;
				}
				.desc {
					color: #999999;
					font-size: 12px;
					margin-left: 10px;
					span {
						margin-right: 2px;
					}
					.red {
						margin-left: 6px;
						[class^=el-icon-] {
							color: #ff3b3b;
							font-weight: bold;
						}
					}
					.green {
						margin-left: 6px;
						[class^=el-icon-] {
							color: #1dc11d;
							font-weight: bold;
						}
					}
				}
			}
		}
		.core-data {
			width: 66.66%;
			display: flex;
			background-color: #fbfbfc;
			min-height: 300px;
			.data-card {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 12px 12px 0;
				.card-title {
					font-size: 14px;
					color: #333333;
					margin-bottom: 10px;
				}
				.card-figure {
					margin: 6px 0;
					.count {
						font-size: 28px;
						color: #0f0f0f;
						margin-left: 20px;
						line-height: 28px;
					}
					.desc {
						font-size: 12px;
						color: #666666;
						margin-left: 6px;
						span {
							margin-right: 2px;
						}
						.red {
							margin-left: 6px;
							[class^=el-icon-] {
								color: #ff3b3b;
								font-weight: bold;
							}
						}
						.green {
							margin-left: 6px;
							[class^=el-icon-] {
								color: #1dc11d;
								font-weight: bold;
							}
						}
					}
				}
				.card-chart {
					height: 120px;
				}
				.card-cone {
					margin: 0 auto;
					width: 235.5px;
					position: relative;
					.cone-main {
						width: 142px;
						height: 123px;
						color: #ffffff;
						background-image: url('~@/assets/images/seller/subscribe-analysis-1.png');
						background-repeat: no-repeat;
						background-size: 100% 100%;
						.cone-item {
							height: 35px;
							text-align: center;
							line-height: 35px;
							font-size: 18px;
						}
					}
					.cone-box {
						width: 93.5px;
						right: 0;
						top: 10px;
						color: #999999;
						position: absolute;
						li {
							p {
								font-size: 14px;
								line-height: 1.28571;
							}
							span {
								font-size: 16px;
								color: #111;
								margin-left: 6px;
							}
							[class^=el-icon-] {
								font-size: 12px;
								line-height: 1;
							}
							&:nth-child(2) {
								margin-left: -20px;
							}
							&:nth-child(3) {
								margin-left: -40px;
							}
						}
					}
				}
				.card-bottom {
					padding: 12px;
					height: 52px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					border-top: 1px solid #e6e6e6;
					.title {
						font-size: 14px;
					}
					.desc {
						color: #999999;
						font-size: 12px;
						margin-left: 10px;
						span {
							margin-right: 2px;
						}
						.red {
							margin-left: 6px;
							[class^=el-icon-] {
								color: #ff3b3b;
								font-weight: bold;
							}
						}
						.green {
							margin-left: 6px;
							[class^=el-icon-] {
								color: #1dc11d;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
	.crowd-search {
		display: flex;
		justify-content: space-between;
		margin-bottom: 18px;
		ul {
			display: flex;
			align-items: center;
			li {
				font-size: 14px;
				margin-right: 10px;
				span {
					color: #3386f1;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
</style>